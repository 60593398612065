import { render, staticRenderFns } from "./CookiesWebsite.vue?vue&type=template&id=6040aaee&scoped=true"
import script from "./CookiesWebsite.vue?vue&type=script&lang=js"
export * from "./CookiesWebsite.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6040aaee",
  null
  
)

export default component.exports
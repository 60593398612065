<template>
  <el-dialog
          :visible.sync="dialogVisible"
          width="620px"
          @close="dialogClose"
          @open="dialogOpen"
  >
    <div slot="title">
      <span style="font-weight: 700;font-size: 18px">{{ form.id ? '编辑' : '新增' }}</span>
    </div>
    <el-form
            v-if="dialogVisible"
            ref="form"
            v-loading="loading"
            :model="form"

            label-width="120px"
    >
      <el-form-item
              label="名称"
              prop="name"
              :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
      >
        <el-input
                v-input-filter
                v-model="form.name"
                maxlength="100"
        />
      </el-form-item>
      <el-form-item
              label="渠道"
              prop="channelId"
              :rules="[ { required: true, message: '渠道不能为空', trigger: 'change' }]"
      >
        <el-select v-model="form.channelId">
          <el-option v-for="(item,index) in channelOption" :key="index" :label="item.channelName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
              label="主题"
              prop="themeId"
              :rules="[ { required: true, message: '主题不能为空', trigger: 'change' }]"
      >
       <el-select v-model="form.themeId">
         <el-option v-for="(item,index) in cookieThemeOption" :key="index" :label="item.title" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
      <el-form-item
              label="描述"
      >
        <el-input
                v-input-filter
                type="textarea"
                v-model="form.remark"
                placeholder="请输入描述"
        />
      </el-form-item>
    </el-form>
    <div
            slot="footer"
            class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
              type="primary"
              :disabled="loading"
              @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { GetChannelOption,saveOrUpdateWebsite,GetWebsiteById,GetThemeOption } from '@/api/cpm/cpm'
  import { success, error } from '@core/utils/utils'

  export default {
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          channelId: '',
          name: '',
          themeId: '',
          id: '',
          remark: '',
        },
        channelOption: [],
        cookieThemeOption: [],
      }
    },
    methods: {
      getWebsiteById(){
        GetWebsiteById(this.form.id).then(res=>{
          const resData = res.data
          if (resData.code === 0) {
            this.form = resData.data
          }
        })
      },
      getThemeOption(){
        GetThemeOption().then(res=>{
          const resData = res.data
          if (resData.code === 0) {
            this.cookieThemeOption = resData.data
          }
        })
      },
      dialogOpen() {
        this.getChannelOption()
        this.getThemeOption()
        if(this.form.id){
          this.getWebsiteById()
        }
      },
      getChannelOption(){
        GetChannelOption().then(res=>{
          const resData = res.data
          if (resData.code === 0) {
            this.channelOption = resData.data
          }
        })
      },
      dialogClose() {
        this.form = {
          channelId: '',
          name: '',
          themeId: '',
          id: '',
          remark: '',
        }
        this.$refs.form.resetFields()
      },
      // eslint-disable-next-line vue/no-dupe-keys
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.form.validate(valid => {
          // eslint-disable-next-line no-empty
          if (valid) {
            this.loading = true
            if(!this.form.id){
              delete this.form.id
            }
            saveOrUpdateWebsite(this.form).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <SearchFilter
          :search.sync="listQuery.SearchStr"
          @handleFilter="handleFilter"
        />
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          label="名称"
          :show-overflow-tooltip="true"
          prop="name"
        />
        <el-table-column
          prop="domainName"
          :show-overflow-tooltip="true"
          label="域名"
        />
        <el-table-column
          prop="remark"
          label="描述"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="themeName"
          :show-overflow-tooltip="true"
          label="主题"
        />
        <el-table-column
          width="100"
          prop="createTime"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="updateTime"
          label="修改时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="120px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row.id)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="del(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="copyPerm"
              :underline="false"
              type="primary"
              @click="copyDialogShow(row)"
            >
              <el-tooltip
                content="获取网站代码"
                placement="top"
                effect="light"
              >
                <i class="el-icon-document-copy" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.pageSize"
        @pagination="getList"
      />
    </b-card>
    <CookiesTrackerSave
      ref="cookiesTrackerSave"
      @handleFilter="handleFilter"
    />
    <CookieCopyDialog ref="cookieCopyDialog" />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import pagination from '@core/components/pagination/Pagination'
import CookiesTrackerSave from '@/views/cookies/CookiesWebsiteSave.vue'
import CookieCopyDialog from '@/views/cookies/CookieCopyDialog.vue'
// eslint-disable-next-line import/no-cycle
import { ListWebsite, DelWebsite } from '@/api/cpm/cpm'
// eslint-disable-next-line import/no-cycle
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'

export default {
  components: {
    pagination,
    CookiesTrackerSave,
    CookieCopyDialog,
  },
  data() {
    return {
      savePerm: findBtnPerm('cookies-website-2'),
      updatePerm: findBtnPerm('cookies-website-3'),
      delPerm: findBtnPerm('cookies-website-4'),
      copyPerm: findBtnPerm('cookies-website-5'),
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'cookies-website-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'cookies-website-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'cookies-website-4')
      this.copyPerm = verifyBtnPerm(btnPerms, 'cookies-website-5')
    })
    this.getList()
  },
  methods: {
    del(id) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          DelWebsite({ id })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.handleFilter()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    copyDialogShow(row) {
      this.$refs.cookieCopyDialog.dialogVisible = true
      this.$refs.cookieCopyDialog.websiteId = row.id
    },
    saveDialogShow(type, id) {
      this.$refs.cookiesTrackerSave.dialogVisible = true
      if (type === 2) {
        this.$refs.cookiesTrackerSave.form.id = id
      }
    },
    detailDialogShow(productId) {
      this.$refs.productDetail.dialogVisible = true
      this.$refs.productDetail.productInfo.id = productId
    },
    getList() {
      this.loading = true
      ListWebsite(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>

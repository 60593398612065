<template>
    <el-dialog
            title="获取同意代码"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            @opened="dialogOpen"
            width="620px"
    >
        <el-card class="mb20">
            你可以在你的所有网站的header内统一贴上该代码，这样只有获取了用户授权，其他cookie才会正常工作。后续你可以直接修改相关配置而不需要重新部署该代码
        </el-card>
        <div class="el-textarea">
            <textarea id="scriptTextarea" style="width: 100%">
                <script src="https://ent.pisecuriti.com/cpm.js?picaas-websiteId={{websiteId}}"></script>
                <script>picaasCpmInit()</script>
            </textarea>
        </div>
        <div
                slot="footer"
                class="dialog-footer"
        >
            <el-button @click="dialogVisible = false">
                取 消
            </el-button>
            <el-button @click="copy">
                复制代码
            </el-button>
            <el-button
                    type="primary"
                    :disabled="loading"
            >
                <el-link style="color: #ffffff" @click="downloadDeployDoc">
                    下载部署文档
                    <i class="el-icon-download"></i>
                </el-link>
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
  import { cookieDeployDoc,DownloadDeployDoc } from '@/api/cpm/cpm'
  export default {
    name: 'CookieCopyDialog',
    data() {
      return {
        accessToken: localStorage.getItem('accessToken'),
        dialogVisible: false,
        loading: false,
        websiteId: '',
        deployDoc: '',

      }
    },
    methods: {
      dialogOpen(){

      },
      downloadDeployDoc(){
        DownloadDeployDoc(this.websiteId)
      },
      getCookieDeployDoc(){
        cookieDeployDoc(this.websiteId).then(res=>{
          const resData = res.data
          this.deployDoc = resData
        })
      },
      copy() {
        let oInput = document.createElement('input')
        oInput.value = document.getElementById('scriptTextarea').value
          document.body.appendChild(oInput)
        // 选中输入框
        oInput.select()
        document.execCommand('Copy')
        this.$message.success('复制成功')
        oInput.remove()
      }
    }
  }
</script>

<style scoped>

</style>
